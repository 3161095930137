<template>
  <section class="section">
    <div class="row">
      <div class="col">
        <Panel type="sheet">
          <template v-slot:headline>{{ parentEventTitle }}</template>
          <template v-slot:body>
            <section class="form-section">
              <p class="form-headline">基本情報</p>
              <FormRow :required="true">
                <template v-slot:label>親イベント名</template>
                <template v-slot:labelNote>（20文字以内）</template>
                <template v-slot:content>
                  <div class="form-content-row">
                    <p v-if="isEdit">{{ form.name }}</p>
                    <input
                      v-else
                      :class="{ 'form-control': true, 'is-error': errors.name }"
                      type="text"
                      name="name"
                      maxlength="20"
                      v-trim
                      v-maxlength
                      v-model="form.name"
                    />
                  </div>
                </template>
              </FormRow>
              <FormRow :required="true">
                <template v-slot:label>事務局名/企業名</template>
                <template v-slot:content>
                  <div class="form-content-row">
                    <label v-if="isEdit && !isGmo" class="form-check form-check-btn">{{ selectedClients }}</label>
                    <SearchSelectDisabled
                      v-else
                      :isError="errors.clients"
                      :options="newClientList"
                      :canEdit="isEdit"
                      :blockKeys="['Delete']"
                      :initialValue="form.clients"
                      @change-selection="handleClientsList"
                    />
                  </div>
                </template>
              </FormRow>
              <FormRow :required="true">
                <template v-slot:label>サブドメイン</template>
                <template v-slot:content>
                  <div class="form-content-row">
                    <p v-if="isEdit">{{ form.subdomain }}</p>
                    <input
                      v-else
                      :class="{ 'form-control': true, 'is-error': errors.subdomain }"
                      type="text"
                      name="subdomain"
                      v-trim
                      v-maxlength
                      maxlength="32"
                      v-model="form.subdomain"
                    />
                  </div>
                </template>
              </FormRow>
            </section>
            <section class="form-section">
              <p class="form-headline">詳細設定</p>
              <FormRow v-if="isGmo || form.useTotalBalanceFlag">
                <template v-slot:label>総残高表示</template>
                <template v-slot:toggle>
                  <label class="form-toggle">
                    <input
                      class="form-toggle-input"
                      type="checkbox"
                      name="vcn"
                      :checked="form.useTotalBalanceFlag"
                      :disabled="!isGmo"
                      @click="handleTotalBalanceFlag"
                    />
                    <span class="form-toggle-set">
                      <span class="form-toggle-bg"></span>
                      <span class="form-toggle-circle"></span>
                    </span>
                  </label>
                </template>
                <template v-slot:content>
                  <div class="form-content-row">
                    <p>
                      イベント一覧画面にて子イベント残高の合算表示がされるようにします。<br/>
                      イベント一覧画面の総残高より直接店舗に支払いを行えるようにします。
                    </p>
                  </div>
                </template>
              </FormRow>
              <FormRow v-if="isUseCpm">
                <template v-slot:label>CPM利用</template>
                <template v-slot:toggle>
                  <label class="form-toggle">
                    <input
                      class="form-toggle-input"
                      type="checkbox"
                      name="vcn"
                      :checked="form.useCpm"
                      :disabled="isClientChild || (isEdit && parentEventDetail.useCpm)"
                      @click="handleUseCpm"
                    />
                    <span class="form-toggle-set">
                      <span class="form-toggle-bg"></span>
                      <span class="form-toggle-circle"></span>
                    </span>
                  </label>
                </template>
              </FormRow>
              <transition name="fade" v-if="form.useCpm && isUseCpm">
                <div class="form-row ml-20">
                  <FormRow :required="form.useCpm">
                    <template v-slot:label>CPM購入（付与）時の割増率</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        購入（付与）金額に割増率を乗じた金額を購入（付与）します。<br />
                        <span class="text-red">※100%で等価値、端数は切り捨てになります。</span><br />
                        <span class="text-red">※例）1,000円購入（付与）時、120%の割増率で1,200円</span>
                      </div>
                      <div class="form-content-row">
                        <div class="form-group">
                          <div class="form-group-item">
                            <input
                              :class="{ 'form-control': true, 'is-error': errors.cpmChargeRate }"
                              type="tel"
                              name="cpmChargeRate"
                              v-number="'integer'"
                              v-model="form.cpmChargeRate"
                            />
                          </div>
                          <div class="form-group-item">%</div>
                        </div>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow v-if="!isClientChild">
                    <template v-slot:label>CPMカード切り替え機能</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <ul class="listGroup">
                          <li v-for="(item, index) in cpmTranferTypes" :key="index" class="listGroup-item bd-0">
                            <label class="form-radio">
                              <input
                                :class="{ 'form-radio-input': true, 'is-error': errors.useSwitchCPMCard }"
                                type="radio"
                                name="useSwitchCPMCard"
                                v-model="form.useSwitchCPMCard"
                                :value="item.value"
                              />
                              <span class="form-radio-label">{{ item.label }}</span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </template>
                  </FormRow>
                </div>
              </transition>
              <FormRow :required="true" v-if="!isEdit || form.canUsePointFlag">
                  <template v-slot:label>支払キャンセル可能期間</template>
                  <template v-slot:subLabel><span class="text-note">支払後から下記の期間が経過するとキャンセルすることができなくなります。</span></template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <div class="form-group">
                        <div class="form-group-item">
                          <p v-if="isEdit && !parentEventDetail.canChangeCancelDate">{{ form.cancelDateNumber }}</p>
                          <input v-else
                            :class="{ 'form-control': true, 'is-error': errors.cancelDateNumber }"
                            type="tel"
                            name="cancelDateNumber"
                            v-number="'integer'"
                            v-model="form.cancelDateNumber"
                          />
                        </div>
                        <div class="form-group-item">日間</div>
                      </div>
                    </div>
                  </template>
              </FormRow>
              <FormRow v-if="!isEdit || form.canUsePointFlag">
                <template v-slot:label>ポイントを利用する</template>
                <template v-slot:toggle>
                  <label class="form-toggle">
                    <input
                      class="form-toggle-input"
                      type="checkbox"
                      name="usePointFlag"
                      :checked="form.usePointFlag"
                      @click="handleUsePointFlag"
                    />
                    <span class="form-toggle-set">
                      <span class="form-toggle-bg"></span>
                      <span class="form-toggle-circle"></span>
                    </span>
                  </label>
                </template>
              </FormRow>
              <div class="ml-20">
                <FormRow v-if="(!isEdit || form.canUsePointFlag) && form.usePointFlag">
                  <template v-slot:content>
                    <div class="form-content-row">
                      <ul class="listGrid">
                        <li v-for="item in pointTypeList" class="listGrid-item" :key="item.value" :class="item.class">
                          <label class="form-radio">
                            <input
                              class="form-radio-input"
                              type="radio"
                              :value="item.value"
                              name="typePoint"
                              v-model="form.typePoint"
                            />
                            <span class="form-radio-label">{{ item.label }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </template>
                </FormRow>
                <FormRow v-if="(!isEdit || form.canUsePointFlag) && form.usePointFlag">
                  <template v-slot:label>ポイント機能を公開する</template>
                  <template v-slot:subLabel>
                    <span class="text-note fz-note">※ポイント機能を公開するをONにするまでフロント画面に表示されず、実際にポイント付与・消費は行われません。</span><br />
                    <span class="text-note fz-note">※ポイント機能を公開するをOFFにした後、ポイント加算タイミングが経過し、実際に付与がされるまではONにする</span><br />
                    <span class="text-note fz-note">ことはできません。</span>
                  </template>
                  <template v-slot:toggle>
                    <label class="form-toggle">
                      <input
                        class="form-toggle-input"
                        type="checkbox"
                        name="publicPointFlag"
                        :checked="form.publicPointFlag"
                        @click="handlePublicPointFlag"
                      />
                      <span class="form-toggle-set ml-40">
                        <span class="form-toggle-bg"></span>
                        <span class="form-toggle-circle"></span>
                      </span>
                    </label>
                  </template>
                </FormRow>
              </div>
              <FormRow>
                <template v-slot:label>支払時に決済音を鳴らす</template>
                <template v-slot:toggle>
                  <label class="form-toggle">
                    <input
                      class="form-toggle-input"
                      type="checkbox"
                      name="useSoundFlag"
                      v-model="form.useSoundFlag"
                      @change="handleUseSoundFlag"
                    />
                    <span class="form-toggle-set">
                      <span class="form-toggle-bg"></span>
                      <span class="form-toggle-circle"></span>
                    </span>
                  </label>
                </template>
                <template v-slot:content>
                  <div class="form-row ml-20 mt-20" v-show="form.useSoundFlag">
                    <FormRow>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <ul class="listGrid">
                            <li v-for="item in soundTypeList" class="listGrid-item" :key="item.value" :class="item.class">
                              <label class="form-radio">
                                <input
                                  :class="{ 'form-radio-input': true, 'is-error': errors.typeSound }"
                                  type="radio"
                                  :value="item.value"
                                  name="typeSound"
                                  v-model="form.typeSound"
                                />
                                <span class="form-radio-label">{{ item.label }}</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </template>
                    </FormRow>
                  </div>
                  <div class="ml-40" v-if="form.useSoundFlag && isSoundWeb">
                    <div class="form-content-row">
                      <p>
                        決済音を鳴らすがONで決済音を変更しない場合はデフォルト音になります。<br/>
                        ボリューム設定を変更しない場合、元の音量(100％)のままとなります。
                      </p>
                    </div>
                    <div class="form-content-row">
                      <button class="btn btn-white" @click="handleOnMusic">デフォルト音テスト</button>
                    </div>
                    <div class="form-content-row">
                      <p>
                        デフォルト音テストより確認することができます。
                      </p>
                    </div>
                  </div>
                </template>
              </FormRow>
              <div class="form-row ml-40" v-show="form.useSoundFlag && isSoundWeb">
                <FormRow>
                <template v-slot:label>決済音を変更する</template>
                <template v-slot:labelNote>（wav形式、aac形式・1ファイル１MBまで。1秒ぐらいのファイル推奨）</template>
                <template v-slot:content>
                  <div class="form-content-row">
                    <ul class="fileUpload">
                      <li class="fileUpload-item-audio">
                        <Fileupload
                          type="audio"
                          :isUploadFileSound="true"
                          :initialUrl="soundName"
                          @uploaded="uploadSound"
                          @removed="deleteSound"
                          :loading="isUploadLoading"
                          :maxSize=1
                          acceptedExtensions="audio/vnd.dlna.adts,audio/wav,audio/aac,.aac"
                          acceptedAllExtensions="audio/vnd.dlna.adts,audio/wav,audio/aac,.aac"
                          :isError="errors['soundId']" />
                      </li>
                    </ul>
                  </div>
                </template>
                </FormRow>
                <FormRow>
                  <template v-slot:label>ボリューム設定を変更する</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <div class="form-select">
                        <select
                          class="form-control form-select-input"
                          name="form."
                          v-model="form.volume"
                        >
                          <option
                            v-for="item in volumeStatus"
                            :key="item.value"
                            :value="item.value"
                          >{{ item.label }}</option>
                        </select>
                      </div>
                    </div>
                  </template>
                </FormRow>
                <FormRow>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <button class="btn btn-white" @click="handleOnVolume">変更音テスト</button>
                    </div>
                    <div class="form-content-row">
                      <p>
                        決済音、ボリューム設定を変更した場合、確認することができます。
                      </p>
                    </div>
                  </template>
                </FormRow>
              </div>
              <FormRow v-if="isGmo">
                <template v-slot:label>TICKETタイプを利用する</template>
                <template v-slot:toggle>
                  <label class="form-toggle">
                    <input
                      class="form-toggle-input"
                      type="checkbox"
                      name="useTicketFlag"
                      :disabled="!isEditableVcn"
                      :checked="form.useTicketFlag"
                      @click="handleUseTicketFlag"
                    />
                    <span class="form-toggle-set">
                      <span class="form-toggle-bg"></span>
                      <span class="form-toggle-circle"></span>
                    </span>
                  </label>
                </template>
              </FormRow>
              <template v-if="!isClientChild">
                <FormRow>
                  <template v-slot:label>事務局/親企業用FAQのURL</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <input
                        :class="{ 'form-control': true, 'is-error': errors.urlFaqParent }"
                        type="text"
                        name="urlFaqParent"
                        v-trim
                        v-model="form.urlFaqParent"
                      />
                    </div>
                  </template>
                </FormRow>
                <FormRow>
                  <template v-slot:label>準事務局/子企業用FAQのURL</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <input
                        :class="{ 'form-control': true, 'is-error': errors.urlFaqChild }"
                        type="text"
                        name="urlFaqChild"
                        v-trim
                        v-model="form.urlFaqChild"
                      />
                    </div>
                  </template>
                </FormRow>
                <FormRow>
                  <template v-slot:label>加盟店/店舗グループ用FAQのURL</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <input
                        :class="{ 'form-control': true, 'is-error': errors.urlFaqStore }"
                        type="text"
                        name="urlFaqStore"
                        v-trim
                        v-model="form.urlFaqStore"
                      />
                    </div>
                  </template>
                </FormRow>
                <FormRow>
                  <template v-slot:label>イベント終了時のURL</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <input
                        :class="{ 'form-control': true, 'is-error': errors.urlFaqCustomer }"
                        type="text"
                        name="urlFaqCustomer"
                        v-trim
                        v-model="form.urlFaqCustomer"
                      />
                    </div>
                  </template>
                </FormRow>
                <FormRow v-if="envPaymentPasscode">
                  <template v-slot:label>支払用パスコード必要可否</template>
                  <template v-slot:subLabel>
                    <div class="ml-20">
                      <span>店舗での支払時に使う支払用パスコードを、顧客がマイページにてON/OFFすることが可能となります。</span><br>
                      <span>※通常チャージタイプ・TICKETチャージタイプの場合の購入（チャージ）時は、支払用パスコードのON/OFFは不可能となり、必須となります。</span>
                    </div>
                  </template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <ul class="listGroup">
                        <li v-for="(item, index) in passcodeTypes" :key="index" class="listGroup-item bd-0">
                          <label class="form-radio">
                            <input
                              :class="{ 'form-radio-input': true, 'is-error': errors.usePaymentPasscode }"
                              type="radio"
                              name="usePaymentPasscode"
                              v-model="form.usePaymentPasscode"
                              :value="item.value"
                              @change="handleUsePaymentPasscode"
                            />
                            <span class="form-radio-label">{{ item.label }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </template>
                </FormRow>
                <template v-if="envPaymentPasscode && isDescriptionShown && !isClientChild">
                  <FormRow style="margin-left: 20px;">
                    <template v-slot:label>新規会員登録時説明文</template>
                    <template v-slot:labelNote>（500文字以内）</template>
                    <template v-slot:subLabel>新規会員登録時の支払用パスコード欄の説明文です。何も指定しない場合はデフォルトの文言が表示されます。</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <textarea
                          :class="{ 'form-control form-textarea': true, 'is-error': errors.descriptionRegister }"
                          name="descriptionRegister"
                          maxlength="500"
                          v-trim
                          v-maxlength
                          v-model="form.descriptionRegister"
                        ></textarea>
                      </div>
                      <div class="form-content-row text-red ml-20" v-if="!isClientChild">
                        <p>デフォルト文言</p>
                        <p>※「支払用パスコードを利用する」のチェックを外すと、店舗での支払時に「支払用パスコード」が不要になります。</p>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow style="margin-left: 20px;">
                    <template v-slot:label>マイページ説明文</template>
                    <template v-slot:labelNote>（500文字以内）</template>
                    <template v-slot:subLabel>マイページの支払用パスコード欄の説明文です。何も指定しない場合はデフォルトの文言が表示されます。</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <textarea
                          :class="{ 'form-control form-textarea': true, 'is-error': errors.descriptionMyPage }"
                          name="descriptionMyPage"
                          maxlength="500"
                          v-trim
                          v-maxlength
                          v-model="form.descriptionMyPage"
                        ></textarea>
                      </div>
                      <div class="form-content-row text-red ml-20" v-if="!isClientChild">
                        <p>デフォルト文言</p>
                        <p>※「支払用パスコードを有効にする」を無効にすると、店舗での支払時に「支払用パスコード」が不要になります。</p>
                      </div>
                    </template>
                  </FormRow>
                </template>
              </template>
            </section>
            <section class="form-section">
              <p class="form-headline">外部連携</p>
              <FormRow :required="true">
                <template v-slot:label>Google map APIキー</template>
                <template v-slot:content>
                  <div class="form-content-row">
                    <input
                      :class="{ 'form-control': true, 'is-error': errors.mapsApiKey }"
                      type="text"
                      name="mapsApiKey"
                      v-trim
                      v-model="form.mapsApiKey"/>
                  </div>
                </template>
              </FormRow>
              <FormRow v-if="isGmo || !!form.vcn">
                <template v-slot:label>VCN連携</template>
                <template v-slot:toggle>
                  <label class="form-toggle">
                    <input
                      class="form-toggle-input"
                      type="checkbox"
                      name="vcn"
                      :checked="form.vcn"
                      :disabled="!isEditableVcn"
                      @click="handleVcnFlag"
                    />
                    <span class="form-toggle-set">
                      <span class="form-toggle-bg"></span>
                      <span class="form-toggle-circle"></span>
                    </span>
                  </label>
                </template>
                <template v-slot:content>
                  <div class="form-content-row">
                    <p>
                      子イベントでVCN 連携を利用した加盟店への支払いを行えるようにします。<br/>
                      VCN 連携を利用するには統括となるPG情報の入力が必要です。
                    </p>
                  </div>
                </template>
              </FormRow>
              <template v-if="form.vcn">
                <div class="form-content-vcn form-row ml-20">
                  <FormRow>
                    <template v-slot:label>サイトID</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <input
                          :class="{ 'form-control': true, 'is-error': errors.vcn.siteId }"
                          :disabled="!isEditableVcn"
                          type="text"
                          name="vcnSiteId"
                          v-trim
                          v-maxlength
                          v-alphabetAndNumericHalfsize
                          maxlength="13"
                          v-model="form.vcn.siteId"
                        />
                      </div>
                    </template>
                  </FormRow>
                  <FormRow>
                    <template v-slot:label>サイトパスワード</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <div class="form-password">
                          <input
                            v-trim
                            v-model="form.vcn.sitePass"
                            :class="{ 'form-control form-password-input': true, 'is-error': errors.vcn.sitePass }"
                            :type="sitePwdVisible ? 'text' : 'password'"
                            :disabled="!isEditableVcn"
                            name="vcnSitePass"
                            v-maxlength
                            v-alphabetAndNumericHalfsize
                          />
                          <label class="form-check form-password-toggle">
                            <input class="form-check-input" type="checkbox" v-model="sitePwdVisible" />
                            <i class="aikon form-password-icon"></i>
                          </label>
                        </div>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow>
                    <template v-slot:label>ショップID</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <input
                          :class="{ 'form-control': true, 'is-error': errors.vcn.shopId }"
                          :disabled="!isEditableVcn"
                          type="text"
                          name="vcnShopId"
                          v-trim
                          v-maxlength
                          maxlength="13"
                          v-model="form.vcn.shopId"
                          v-alphabetAndNumericHalfsize
                        />
                      </div>
                    </template>
                  </FormRow>
                  <FormRow>
                    <template v-slot:label>ショップパスワード</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <div class="form-password">
                          <input
                            v-trim
                            v-model="form.vcn.shopPass"
                            :class="{ 'form-control form-password-input': true, 'is-error': errors.vcn.shopPass }"
                            :type="shopPwdVisible ? 'text' : 'password'"
                            :disabled="!isEditableVcn"
                            name="vcnShopPass"
                            v-maxlength
                            v-alphabetAndNumericHalfsize
                          />
                          <label class="form-check form-password-toggle">
                            <input class="form-check-input" type="checkbox" v-model="shopPwdVisible" />
                            <i class="aikon form-password-icon"></i>
                          </label>
                        </div>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow>
                    <template v-slot:label>companyId（会社識別ID）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <input
                          :class="{ 'form-control': true, 'is-error': errors.vcn.companyId }"
                          :disabled="!isEditableVcn"
                          type="text"
                          v-number
                          name="vcnCompanyId"
                          v-trim
                          v-model="form.vcn.companyId"
                        />
                      </div>
                    </template>
                  </FormRow>
                  <FormRow>
                    <template v-slot:label>rcnAlias（RCNエイリアス）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <input
                          v-trim
                          v-model="form.vcn.rcnAlias"
                          :class="{ 'form-control form-password-input': true, 'is-error': errors.vcn.rcnAlias }"
                          type="text"
                          :disabled="!isEditableVcn"
                          name="vcnRcnAlias"
                        />
                      </div>
                    </template>
                  </FormRow>
                  <FormRow>
                    <template v-slot:label>templateId（テンプレートID）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <input
                          :class="{ 'form-control': true, 'is-error': errors.vcn.templateId }"
                          :disabled="!isEditableVcn"
                          type="text"
                          v-number
                          name="vcnTemplateId"
                          v-trim
                          v-model="form.vcn.templateId"
                        />
                      </div>
                    </template>
                  </FormRow>
                  <FormRow>
                    <template v-slot:label>supplierId（サプライヤーID）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <input
                          v-trim
                          v-model="form.vcn.supplierId"
                          :class="{ 'form-control form-password-input': true, 'is-error': errors.vcn.supplierId }"
                          type="text"
                          v-number
                          :disabled="!isEditableVcn"
                          name="vcnSupplierId"
                        />
                      </div>
                    </template>
                  </FormRow>
                </div>
              </template>
              <template>
                <FormRow v-if="isUseOmise && isGmo">
                  <template v-slot:label>GMOおみせアプリ連携</template>
                  <template v-slot:subLabel>
                    <span class="text-note">GMOおみせアプリのモバイル会員番号とデジタルPayの顧客IDを連動します。</span><br>
                    <span class="text-note">GMOおみせアプリとの連携については営業スタッフにご確認ください。</span>
                  </template>
                  <template v-slot:toggle>
                    <label class="form-toggle">
                      <input
                        :class="{ 'form-toggle-input': true, 'is-error': errors.useOmise }"
                        type="checkbox"
                        name="useOmise"
                        v-model="form.useOmise"
                        @change="handleUseOmise"
                      />
                      <span class="form-toggle-set">
                        <span class="form-toggle-bg"></span>
                        <span class="form-toggle-circle"></span>
                      </span>
                    </label>
                  </template>
                </FormRow>
                <template v-if="isUseOmise && form.useOmise && isGmo">
                  <div class="form-content-vcn form-row ml-20">
                    <FormRow>
                      <template v-slot:label>ログインID</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <input
                            :class="{ 'form-control': true, 'is-error': errors.omiseId }"
                            :disabled="true"
                            type="text"
                            name="omiseId"
                            v-trim
                            v-model="form.omiseId"
                          />
                        </div>
                      </template>
                    </FormRow>
                    <FormRow>
                      <template v-slot:label>ログインパスワード</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <input
                            :class="{ 'form-control': true, 'is-error': errors.omisePassword }"
                            :disabled="true"
                            type="text"
                            name="omisePassword"
                            v-trim
                            v-model="form.omisePassword"
                          />
                        </div>
                      </template>
                    </FormRow>
                  </div>
                </template>
                <FormRow v-if="isUseVerificationIdentity && (isGmo || ($permission.isOffice() && form.identityVerification))">
                  <template v-slot:label>本人確認連携</template>
                  <template v-slot:subLabel>
                    <span class="text-note">会員登録時、編集時にマイナンバーカードでの認証を利用できるようにします。</span><br>
                    <span class="text-note">本人確認アプリとの連携については営業スタッフにご確認してください。</span>
                  </template>
                  <template v-slot:toggle>
                    <label class="form-toggle">
                      <input
                        class="form-toggle-input"
                        type="checkbox"
                        name="identityVerification"
                        :disabled="$permission.isOffice()"
                        v-model="form.identityVerification"
                        @change="handleIdentityVerification"
                      />
                      <span class="form-toggle-set">
                        <span class="form-toggle-bg"></span>
                        <span class="form-toggle-circle"></span>
                      </span>
                    </label>
                  </template>
                </FormRow>
                <template v-if="form.identityVerification && isUseVerificationIdentity">
                  <div class="form-content-vcn form-row ml-20">
                    <FormRow v-if="isGmo">
                      <template v-slot:label>本人確認連携先</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <ul class="listGroup">
                            <li v-for="(item, index) in partnerIdentities" :key="index" class="listGroup-item bd-0">
                              <label class="form-radio">
                                <input
                                  :class="{ 'form-radio-input': true, 'is-error': errors.partnerIdentity }"
                                  type="radio"
                                  name="partnerIdentity"
                                  v-model="form.partnerIdentity"
                                  :value="item.value"
                                  @change="resetIdentityForm"
                                />
                                <span class="form-radio-label">{{ item.label }}</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </template>
                    </FormRow>
                    <template v-if="!!form.partnerIdentity && isGmo">
                      <template v-if="isCivilios">
                        <FormRow>
                          <template v-slot:label>ホスト（host）</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <input
                                :class="{ 'form-control': true, 'is-error': errors.hostIdentity }"
                                type="text"
                                name="hostIdentity"
                                v-trim
                                v-alphaNumSpecialHalfsize
                                v-model="form.hostIdentity"
                              />
                            </div>
                          </template>
                        </FormRow>
                        <FormRow>
                          <template v-slot:label>レルム（realm）</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <input
                                :class="{ 'form-control': true, 'is-error': errors.realm }"
                                type="text"
                                name="realm"
                                v-trim
                                v-alphabetAndNumericHalfsize
                                v-model="form.realm"
                              />
                            </div>
                          </template>
                        </FormRow>
                      </template>
                      <template v-if="!isDigitalAgency">
                        <FormRow>
                          <template v-slot:label>クライアントID</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <input
                                :class="{ 'form-control': true, 'is-error': errors.clientIdIdentity }"
                                type="text"
                                name="clientIdIdentity"
                                v-trim
                                v-alphaNumSpecialHalfsize
                                v-model="form.clientIdIdentity"
                              />
                            </div>
                          </template>
                        </FormRow>
                        <FormRow>
                          <template v-slot:label>クライアントシークレット</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-password">
                                <input
                                  :class="{ 'form-control form-password-input': true, 'is-error': errors.passClientIdentity }"
                                  :type="clientPwdVisible ? 'text' : 'password'"
                                  name="passClientIdentity"
                                  v-trim
                                  v-alphaNumSpecialHalfsize
                                  v-model="form.passClientIdentity"
                                />
                                <label class="form-check form-password-toggle">
                                  <input class="form-check-input" type="checkbox" v-model="clientPwdVisible" />
                                  <i class="aikon form-password-icon"></i>
                                </label>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </template>
                      <template v-else>
                        <FormRow v-if="isGmo">
                          <template v-slot:label>接続先</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <ul class="listGroup">
                                <li v-for="(item, index) in digitalAgencies" :key="index" class="listGroup-item bd-0">
                                  <label class="form-radio">
                                    <input
                                      :class="{ 'form-radio-input': true, 'is-error': errors.typeEndPointDigitalIdentity }"
                                      type="radio"
                                      name="typeEndPointDigitalIdentity"
                                      v-model="form.typeEndPointDigitalIdentity"
                                      :value="item.value"
                                    />
                                    <span class="form-radio-label">{{ item.label }}</span>
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </template>
                        </FormRow>
                        <FormRow v-if="isGmo">
                          <template v-slot:label>クライアントID</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <input
                                :class="{ 'form-control': true, 'is-error': errors.clientIdDigitalIdentity }"
                                type="text"
                                name="clientIdDigitalIdentity"
                                v-trim
                                v-alphaNumSpecialHalfsize
                                v-model="form.clientIdDigitalIdentity"
                              />
                            </div>
                          </template>
                        </FormRow>
                      </template>
                    </template>
                    <FormRow v-if="!isClientChild">
                      <template v-slot:label>本人確認有効期限</template>
                      <template v-slot:subLabel><span class="d-block text-mc">本人確認後、以下の期間が経過時、次回ログイン時に自動的に本人確認画面に遷移するようになります。</span></template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="form-group">
                            <div class="form-group-item">
                              <input
                                :class="{ 'form-control': true, 'is-error': errors.expirationIdentityDays }"
                                type="tel"
                                name="expirationIdentityDays"
                                v-number="'integer'"
                                maxlength="3"
                                v-maxlength
                                v-model="form.expirationIdentityDays"
                              />
                            </div>
                            <div class="form-group-item">日</div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                  </div>
                </template>
                <FormRow v-if="isUseSms && (isGmo || ($permission.isOffice() && form.useSms))">
                  <template v-slot:label>SMS認証連携</template>
                  <template v-slot:subLabel>
                    <span class="text-note">マイページにて携帯電話番号にSMSを通じて認証を利用できるようにします。</span><br>
                    <span class="text-note">SMS認証との連携については営業スタッフにご確認ください。</span>
                  </template>
                  <template v-slot:toggle>
                    <label class="form-toggle">
                      <input
                        class="form-toggle-input"
                        type="checkbox"
                        name="useSms"
                        :disabled="$permission.isOffice()"
                        v-model="form.useSms"
                        @change="handleUseSms"
                      />
                      <span class="form-toggle-set">
                        <span class="form-toggle-bg"></span>
                        <span class="form-toggle-circle"></span>
                      </span>
                    </label>
                  </template>
                </FormRow>
                <template v-if="isUseSms && form.useSms">
                  <div class="form-content-vcn form-row ml-20">
                    <FormRow v-if="isGmo">
                      <template v-slot:label>APIトークン（Authorization）</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <input
                            :class="{ 'form-control': true, 'is-error': errors.smsToken }"
                            v-alphaNumSpecialHalfsize
                            type="text"
                            name="smsToken"
                            v-trim
                            v-model="form.smsToken"
                          />
                        </div>
                      </template>
                    </FormRow>
                    <template v-if="!isClientChild">
                      <FormRow class="text-mc" v-if="infor.setUseSmsMemberRegistation">
                        <template v-slot:label>SMS会員登録・携帯電話番号ログイン</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <ul class="listGroup">
                              <li v-for="(item, index) in smsPhoneTypes" :key="index" class="listGroup-item bd-0">
                                <label class="form-radio">
                                  <input
                                    :class="{ 'form-radio-input': true, 'is-error': errors.useLoginSms }"
                                    type="radio"
                                    name="useLoginSms"
                                    v-model="form.useLoginSms"
                                    :value="item.value"
                                  />
                                  <span class="form-radio-label">{{ item.label }}</span>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </template>
                      </FormRow>
                      <FormRow class="text-mc" v-if="isUseSms">
                        <template v-slot:label>SMS認証有効期限</template>
                        <template v-slot:subLabel><span class="text-note">SMS認証後、以下の期間が経過時、次回ログイン時に自動的にSMS認証画面に遷移するようになります。</span></template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <div class="form-group">
                              <div class="form-group-item">
                                <input
                                  :class="{ 'form-control': true, 'is-error': errors.expirationSmsDays }"
                                  type="tel"
                                  name="expirationSmsDays"
                                  maxlength="3"
                                  v-maxlength
                                  v-number="'integer'"
                                  v-model="form.expirationSmsDays"
                                />
                              </div>
                              <div class="form-group-item">日</div>
                            </div>
                          </div>
                        </template>
                      </FormRow>
                    </template>
                  </div>
                </template>
                <template v-if="hasEnvUseOtherApp && !isClientChild">
                  <FormRow>
                    <template v-slot:label>アプリ外部連携</template>
                    <template v-slot:subLabel>
                      <span class="text-note">外部アプリとデジタルPayの顧客IDを連動します。</span><br>
                      <span class="text-note">外部アプリとの連携については営業スタッフにご確認ください。</span>
                    </template>
                    <template v-slot:toggle>
                      <label class="form-toggle">
                        <input
                          :class="{ 'form-toggle-input': true, 'is-error': errors.useLinkageNecFlag }"
                          type="checkbox"
                          name="useLinkageNecFlag"
                          v-model="form.useLinkageNecFlag"
                          @change="handleUseLinkageNecFlag"
                        />
                        <span class="form-toggle-set">
                          <span class="form-toggle-bg"></span>
                          <span class="form-toggle-circle"></span>
                        </span>
                      </label>
                    </template>
                  </FormRow>
                  <template v-if="form.useLinkageNecFlag">
                    <div class="form-content-vcn form-row ml-20">
                      <FormRow>
                        <template v-slot:label>API KEY</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <input
                              :class="{ 'form-control form-control-linkage': true, 'is-error': errors.necApiAuthKey }"
                              :disabled="true"
                              type="text"
                              name="necApiAuthKey"
                              v-trim
                              v-model="form.necApiAuthKey"
                            />
                          </div>
                        </template>
                      </FormRow>
                    </div>
                  </template>
                </template>
              </template>
            </section>
            <section class="form-section">
              <p class="form-headline">加盟店に対する設定</p>
              <FormRow>
                <template v-slot:label>店舗設定の編集可否</template>
                <template v-slot:content>
                  <div class="form-content-row">
                    <p>店舗基本設定は店舗管理にある店舗情報の設定画面となります。</p>
                  </div>
                  <div class="form-content-row">
                    <ul class="listGrid">
                      <li class="listGrid-item">
                        <label class="form-check form-check-btn">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked
                            disabled
                            @click="handleChangeSetting($event, 'Initial')"
                          />
                          <span class="form-check-label">
                            初期設定
                          </span>
                        </label>
                      </li>
                      <li class="listGrid-item">
                        <label class="form-check form-check-btn">
                          <input class="form-check-input" type="checkbox" v-model="form.shopSettings.basic" />
                          <span class="form-check-label">
                            店舗基本設定
                          </span>
                        </label>
                      </li>
                      <li class="listGrid-item">
                        <label class="form-check form-check-btn">
                          <input class="form-check-input" type="checkbox" v-model="form.shopSettings.advanced" />
                          <span class="form-check-label">
                            店舗詳細設定
                          </span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </template>
              </FormRow>
              <FormRow>
                <template v-slot:label>顧客管理機能の利用許可</template>
                <template v-slot:toggle>
                  <label class="form-toggle">
                    <input
                      class="form-toggle-input"
                      type="checkbox"
                      name="showCustomerFlag"
                      v-model="form.showCustomerFlag"
                    />
                    <span class="form-toggle-set">
                      <span class="form-toggle-bg"></span>
                      <span class="form-toggle-circle"></span>
                    </span>
                  </label>
                </template>
              </FormRow>
              <FormRow>
                <template v-slot:label>加盟店の機能</template>
                <template v-slot:subLabel>お知らせ以外の項目をONからOFFに変更した場合、フロント画面の各加盟店ページに掲載されていたデータは参照することができません。</template>
                <template v-slot:content>
                  <div class="form-content-row">
                    <ul class="listGrid">
                      <li class="listGrid-item">
                        <label class="form-check form-check-btn">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="form.shopMenus.gallery"
                          />
                          <span class="form-check-label">
                            ギャラリー
                          </span>
                        </label>
                      </li>
                      <li class="listGrid-item">
                        <label class="form-check form-check-btn">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="form.shopMenus.menu"
                          />
                          <span class="form-check-label">
                            メニュー
                          </span>
                        </label>
                      </li>
                      <li class="listGrid-item">
                        <label class="form-check form-check-btn">
                          <input class="form-check-input" type="checkbox" v-model="form.shopMenus.coupon" />
                          <span class="form-check-label">
                            クーポン
                          </span>
                        </label>
                      </li>
                      <li class="listGrid-item">
                        <label class="form-check form-check-btn">
                          <input class="form-check-input" type="checkbox" v-model="form.shopMenus.news" />
                          <span class="form-check-label">
                            お知らせ
                          </span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </template>
              </FormRow>
              <FormRow v-if="!isClientChild">
                <template v-slot:label>加盟店機能OFF時のフロント画面表示</template>
                <template v-slot:toggle>
                  <label class="form-toggle">
                    <input
                      class="form-toggle-input"
                      type="checkbox"
                      name="displayShopMenuFront"
                      :checked="form.displayShopMenuFront"
                      @click="handleDisplayShopMenuFront"
                    />
                    <span class="form-toggle-set">
                      <span class="form-toggle-bg"></span>
                      <span class="form-toggle-circle"></span>
                    </span>
                  </label>
                </template>
                <template v-slot:content>
                  <div class="form-content-row">
                    <p>
                      加盟店の機能がOFFの場合でもフロント画面に各機能の利用を許可する場合ONにします。
                    </p>
                  </div>
                </template>
              </FormRow>
              <FormRow v-if="!isClientChild">
                <template v-slot:label>加盟店・店舗グループのログイン許可</template>
                <template v-slot:toggle>
                  <label class="form-toggle">
                    <input
                      class="form-toggle-input"
                      type="checkbox"
                      name="allowStoreGroupLogin"
                      v-model="form.allowStoreGroupLogin"
                    />
                    <span class="form-toggle-set">
                      <span class="form-toggle-bg"></span>
                      <span class="form-toggle-circle"></span>
                    </span>
                  </label>
                </template>
                <template v-slot:content>
                  <div class="form-content-row">
                    <p>
                      OFFにした場合、加盟店・店舗グループは管理画面にログインできなくなります。
                    </p>
                  </div>
                </template>
              </FormRow>
            </section>
            <section class="form-section">
              <p class="form-headline">店舗カテゴリー</p>
              <FormRow>
                <template v-slot:content>
                  <ul class="listGroup">
                    <li class="listGroup-item">
                      <div class="form-group">
                        <div class="form-group-item">
                          <input
                            :class="{ 'form-control': true, 'is-error': errors.tagName }"
                            type="text"
                            name="tagName"
                            v-trim
                            v-maxlength
                            v-model="tagName"
                            maxlength="12"
                          />
                        </div>
                        <div class="form-group-item">
                          <button class="btn btn-black" type="button" @click="addTag">追加する</button>
                        </div>
                      </div>
                    </li>
                    <li class="listGroup-item">
                      <p v-if="categoriesList.length == 0">カテゴリーの登録がありません</p>
                      <ul v-if="categoriesList.length > 0" class="listGrid">
                        <li class="listGrid-item" v-for="tag in categoriesList" :key="tag.index">
                          <div class="tag">
                            {{ tag }}
                            <button class="tag-delete" type="button" @click="deleteTag(tag)">
                              <i class="aikon aikon-cross tag-delete-icon"></i>
                            </button>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </template>
              </FormRow>
            </section>
          </template>
        </Panel>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength, minLength, between, numeric } from 'vuelidate/lib/validators';
//components
import FormRow from '@/components/FormRow.vue';
import SearchSelectDisabled from '@/components/SearchSelectDisabled.vue';
import Fileupload from '@/components/Fileupload.vue';
//mixins
import error from '@/mixins/plugin/error';
//helpers
import { formatArray, convertBooleanValues } from '@/helpers/formatData';
import {
  isNumberLowercaseDash,
  notConsecutiveDashes,
  isDateWithMinMaxValue,
  isCharWithMinMaxLength,
  isUrl,
  isNumberAndAlphabetSpecialHalfsize,
} from "@/helpers/validators";
//constants
import { StatusConstants } from '@/constants/status';
import { buildFormData } from '@/helpers/file';
import { EventConstants } from '@/constants/event';

export default {
  name: 'SettingEventRegisterParent',
  data: function () {
    return {
      form: {},
      pageName: '設定',
      tagName: '',
      errors: {
        tagName: false,
      },
      prefixs: ['vcn'],
      sitePwdVisible: false,
      shopPwdVisible: false,
      clientPwdVisible: false,
      defaultShopSetting: {
        initial: true,
        basic: true,
        advanced: true,
      },
      defaultShopMenus: {
        gallery: true,
        menu: true,
        coupon: true,
        news: true
      },
      isUploadLoading: false,
      soundName: '',
      volumeStatus: StatusConstants.volumeStatus,
      pointTypeList: Object.values(StatusConstants.pointType),
      soundTypeList: Object.values(StatusConstants.soundType),
      alarm: new Audio(),
      file: '',
      audioSrcObj: null,
      passcodeTypes: Object.values(EventConstants.passcodeType),
      cpmTranferTypes: Object.values(EventConstants.cpmTransferType),
      smsPhoneTypes: Object.values(EventConstants.smsPhoneType),
      selectedIds: new Set(),
    };
  },
  computed: {
    ...mapGetters({
      parentEventDetail: 'event/parentEventDetail',
      clientList: 'client/clientList',
      isUserableCPM: 'auth/isUserableCPM',
      canUseCpmParentEvent: 'event/canUseCpmParentEvent',
      isClientChild: 'auth/isClientChild',
      infor: 'auth/infor',
      isUseOmise: 'auth/isUseOmise',
      isUseVerificationIdentity: 'auth/isUseVerificationIdentity',
      isUseSms: 'auth/isUseSms',
      envPaymentPasscode: 'auth/envPaymentPasscode',
    }),
    subdomain() {
      return this.$route.params.subdomain;
    },
    categoriesList() {
      const nameOfCategories = this.convertToArray(this.form.categories).map((item) => item.name);
      const addCategories = this.convertToArray(this.form.addCategories);
      return nameOfCategories.concat(addCategories);
    },
    selectedClients() {
      return formatArray(this.form.clients, 'name');
    },
    isEditableVcn() {
      return this.subdomain ? this.parentEventDetail.isEditableVcn : true;
    },
    isGmo() {
      return this.$permission.isGmo();
    },
    isUseCpm() {
      return this.isUserableCPM || (this.isEdit && this.canUseCpmParentEvent);
    },
    defaultVolumeAudio() {
      return new Audio(this.infor?.defaultSound);
    },
    parentEventTitle() {
      return this.isEdit ? "親イベント編集" : "親イベント登録"
    },
    partnerIdentities() {
      return Object.values(EventConstants.partnerIdentityType);
    },
    isCivilios() {
      return this.form.partnerIdentity === EventConstants.partnerIdentityType.civilios.value;
    },
    isxID() {
      return this.form.partnerIdentity === EventConstants.partnerIdentityType.xid.value;
    },
    isDescriptionShown() {
      return this.form?.usePaymentPasscode === EventConstants.passcodeType.setting.value;
    },
    isSoundWeb() {
      return [StatusConstants.soundType.web.value, StatusConstants.soundType.nec.value].includes(this.form.typeSound);
    },
    hasEnvUseOtherApp() {
      return this.infor?.envUseOtherApp;
    },
    isDigitalAgency() {
      return this.form?.partnerIdentity === EventConstants.partnerIdentityType.digital.value;
    },
    digitalAgencies() {
      return Object.values(EventConstants.digitalAgencyType);
    },
    newClientList() {
      return this.clientList.map(item => ({
        ...item,
        isNewItem: !this.selectedIds.has(item.id),
      }));
    }
  },
  components: {
    FormRow,
    SearchSelectDisabled,
    Fileupload
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    }
  },
  mixins: [error],
  validations(){
    let form = {
      name: { required, maxLength: maxLength(20) },
      clients: { selectRequired: required },
      subdomain: { required, isNumberLowercaseDash, notConsecutiveDashes , minLength: minLength(3), maxLength: maxLength(32) },
      cancelDateNumber: { required, dateWithMinMaxValue: isDateWithMinMaxValue(0, 180) },
      useCpm: {},
      cpmChargeRate: {},
      useSwitchCPMCard: {},
      urlFaqParent: { isUrl },
      urlFaqChild: { isUrl },
      urlFaqStore: { isUrl },
      urlFaqCustomer: { isUrl },
      usePaymentPasscode: {},
      descriptionRegister: { maxLength: maxLength(500) },
      descriptionMyPage: { maxLength: maxLength(500) },
      mapsApiKey: { required },
      vcn: {},
      partnerIdentity: {},
      hostIdentity: {},
      realm: {},
      clientIdIdentity: {},
      passClientIdentity: {},
      typeEndPointDigitalIdentity: {},
      clientIdDigitalIdentity: {},
      expirationIdentityDays: { between: between(0, 999) },
      useLoginSms: {},
      expirationSmsDays: { between: between(0, 999) },
      useLinkageNecFlag: {},
      necApiAuthKey: {},
    };
    if (this.form.vcn) {
      form.vcn = {
        siteId: { required, characterWithMinLength: minLength(13) },
        sitePass: { required, characterWithMinMaxLength: isCharWithMinMaxLength(8,64) },
        shopId: { required, characterWithMinLength: minLength(13) },
        shopPass: { required, characterWithMinMaxLength: isCharWithMinMaxLength(8,64) },
        companyId: { required, isNumberHalfsize: numeric },
        rcnAlias: { required },
        templateId: { required, isNumberHalfsize: numeric },
        supplierId: { required, isNumberHalfsize: numeric },
      }
    }
    if (this.form.identityVerification) {
      form = { ...form, partnerIdentity: { selectRequired: required } };
      if (this.form.partnerIdentity && !this.isDigitalAgency) {
        form = {
          ...form,
          hostIdentity: this.isCivilios ? { required, numberAndAlphabetSpecialHalfsize: isNumberAndAlphabetSpecialHalfsize() } : {},
          realm: this.isCivilios ? { required } : {},
          clientIdIdentity: { required, numberAndAlphabetSpecialHalfsize: isNumberAndAlphabetSpecialHalfsize() },
          passClientIdentity: { required },
        }
      }
      if (this.isDigitalAgency) {
        form = {
          ...form,
          typeEndPointDigitalIdentity: { selectRequired: required },
          clientIdDigitalIdentity: { required, numberAndAlphabetSpecialHalfsize: isNumberAndAlphabetSpecialHalfsize() }
        }
      }
    }
    if (this.isUseSms && this.form.useSms) {
      form.smsToken = { required };
    }
    if (this.form.useCpm) {
      form.cpmChargeRate = { required, between: between(1, 1000) };
    }
    return { form }
  },
  watch: {
    'form.typePoint' (val) {
      const isChangeCommonToOwn = (this.parentEventDetail.typePoint === StatusConstants.pointType.all.value) && (val === StatusConstants.pointType.special.value);
      const isChangeOwnToCommon = (this.parentEventDetail.typePoint === StatusConstants.pointType.special.value) && (val === StatusConstants.pointType.all.value);
      const grantedPointCondition = this.parentEventDetail?.hasUnavailablePoint && !this.parentEventDetail?.canChangeTypePointToCommon;
      const isCommonToOwn = isChangeCommonToOwn && !this.parentEventDetail.canChangeTypePoint;
      if (this.form.usePointFlag) {
        if (this.isEdit && grantedPointCondition && isChangeOwnToCommon) {
          this.$message.showError('hasGrantedPoint');
          this.$nextTick(() => {
            this.form.typePoint = StatusConstants.pointType.special.value;
          });
        } else if (this.form.publicPointFlag && (isChangeOwnToCommon || isCommonToOwn)) {
          this.$message.showError('notChangeWhenOnPublicFunction');
          this.$nextTick(() => {
            this.form.typePoint = isChangeOwnToCommon ? StatusConstants.pointType.special.value : StatusConstants.pointType.all.value;
          });
        } else if (this.isEdit && isCommonToOwn && this.parentEventDetail?.hasPointHistory) {
          this.$message.showError('notChangeCommonToOwnIfHasPoint');
          this.$nextTick(() => {
            this.form.typePoint = StatusConstants.pointType.all.value;
          });
        } else if (this.isEdit && isCommonToOwn && !this.parentEventDetail?.hasPointHistory && !this.form.publicPointFlag) {
          this.$message.showError('notChangeCommonToOwnIfNoHasPoint');
          this.$nextTick(() => {
            this.form.typePoint = StatusConstants.pointType.all.value;
          });
        }
      }
    },
    'form.identityVerification'(val) {
      if (!val) {
        this.form.partnerIdentity = null;
        this.resetIdentityForm();
      }
    },
    'form.useSms'(val) {
      if (!val) {
        this.form.smsToken = null;
      }
    },
    'form.useSwitchCPMCard' (val) {
      const hasOnCpm = (this.parentEventDetail?.useSwitchCPMCard === EventConstants.cpmTransferType.setting.value) && (val === EventConstants.cpmTransferType.default.value);
      if (this.form.useCpm) {
        if (this.isEdit && hasOnCpm) {
          this.$message.showError('notChangeCpmOff');
          this.$nextTick(() => {
            this.form.useSwitchCPMCard = EventConstants.cpmTransferType.setting.value;
          });
        }
      }
    },
    'form.useLoginSms' (newVal, oldVal) {
      const hasEmailData = [EventConstants.smsPhoneType.yes.value, EventConstants.smsPhoneType.email.value, EventConstants.smsPhoneType.phone.value];
      const hasOnEmail = oldVal && (hasEmailData.includes(this.parentEventDetail?.useLoginSms)) && (newVal === EventConstants.smsPhoneType.none.value);
      if (this.form.useSms) {
        if (this.isEdit && hasOnEmail) {
          let newMessage = (oldVal === EventConstants.smsPhoneType.yes.value) ? 'notChangeYesOff' : (oldVal === EventConstants.smsPhoneType.email.value) ? 'notChangeEmailOff' : 'notChangePhoneOff';
          this.$message.showError(newMessage);
          this.$nextTick(() => {
            this.form.useLoginSms = oldVal;
          });
        }
      }
    },
    'form.typeSound' (val) {
      if (val && ![StatusConstants.soundType.web.value, StatusConstants.soundType.nec.value].includes(val)) {
        this.soundName = '';
        this.form.volume = 100;
      }
    }
  },
  methods: {
    initForm() {
      this.form = {
        name: '',
        subdomain: '',
        mapsApiKey: '',
        vcn: null,
        useTotalBalanceFlag: 0,
        clients: [],
        showCustomerFlag: false,
        categories: [],
        addCategories: [],
        deleteCategories: [],
        shopSettings: this.defaultShopSetting,
        shopMenus: this.defaultShopMenus,
        cancelDateNumber: 180,
        usePointFlag: 0,
        typePoint: 1,
        publicPointFlag: 0,
        allowStoreGroupLogin: 1,
        displayShopMenuFront: 0,
        useCpm: false,
        cpmChargeRate: null,
        useSoundFlag: false,
        useTicketFlag: 0,
        soundId: null,
        volume: 100,
        urlFaqParent: '',
        urlFaqChild: '',
        urlFaqStore: '',
        urlFaqCustomer: '',
        useOmise: 0,
        identityVerification: 0,
        omiseId: null,
        omisePassword: null,
        hostIdentity: null,
        realm: null,
        clientIdIdentity: null,
        passClientIdentity: null,
        partnerIdentity: null,
        useSms: false,
        smsToken: null,
        usePaymentPasscode: 0,
        useSwitchCPMCard: 0,
        descriptionRegister: null,
        descriptionMyPage: null,
        expirationIdentityDays: null,
        useLoginSms: 0,
        expirationSmsDays: null,
        typeSound: 1,
        necApiAuthKey: null,
        useLinkageNecFlag: 0,
        typeEndPointDigitalIdentity: null,
        clientIdDigitalIdentity: null,
      }
    },
    handleClientsList(value) {
      this.form.clients = value;
    },
    handleVcnFlag(e) {
      if (this.isEdit && !this.isEditableVcn) {
        this.$message.showError('notEditParentEventVcn');
        e.target.checked = !e.target.checked;
      } else if (e.target.checked) {
        if (this.form.usePointFlag) {
          this.$message.showError('notOpenUsePointAndUseVcn');
          e.target.checked = false;
          this.form.vcn = null;
        } else if (this.form.useLinkageNecFlag) {
          this.$message.showError('notOffLinkageNecVcn');
          e.target.checked = false;
          this.form.vcn = null;
        } else {
          this.$message.reset();
          this.form.vcn = {};
        }
      } else {
        this.$message.reset();
        this.form.vcn = null;
      }
    },
    handleTotalBalanceFlag(e) {
      if (e.target.checked) {
        if (this.form.usePointFlag) {
          this.$message.showError('notOpenUsePointAndTotalBalanceFlag');
          e.target.checked = false;
          this.form.useTotalBalanceFlag = 0;
        } else if (this.form.useTicketFlag) {
          this.$message.showError('notUseTicketAndBalanceFlag');
          e.target.checked = false;
          this.form.useTotalBalanceFlag = 0;
        } else {
          this.$message.reset();
          this.form.useTotalBalanceFlag = 1;
        }
      } else {
        this.$message.reset();
        this.form.useTotalBalanceFlag = 0;
      }
    },
    handleUseSoundFlag() {
      if (!this.form.useSoundFlag) {
        this.form.volume = 100;
        this.soundName = 'ファイルを選択';
        this.file = '';
        this.audioSrcObj = '';
        this.alarm = new Audio();
      }
      if (this.form.useSoundFlag) {
        this.form.typeSound = 1;
      } else {
        this.form.typeSound = 0;
      }
    },
    handlePublicPointFlag(e) {
      if (e.target.checked) {
        if (!this.parentEventDetail?.canOnPublicPoint && this.parentEventDetail?.hasUnavailablePoint && !this.parentEventDetail?.publicPointFlag) {
          this.$message.showError('notOnPublicFunction');
          e.target.checked = false;
        } else if (this.isEdit && this.parentEventDetail?.inProgressTransfer && !this.parentEventDetail?.publicPointFlag) {
          this.$message.showError('notChangeOnWhenAtPeriodTransfer');
          e.target.checked = false;
        } else {
          this.form.publicPointFlag = 1;
        }
      } else {
        this.$message.reset();
        this.form.publicPointFlag = 0;
      }
    },
    handleUsePointFlag(e) {
      if (e.target.checked) {
        if (this.form.useTotalBalanceFlag && this.form.vcn) {
          this.$message.showError('notOpenUsePointAndUseVcnAndTotalBalanceFlag');
          e.target.checked = false;
        } else if (this.form.useTotalBalanceFlag) {
          this.$message.showError('notOpenUsePointAndTotalBalanceFlag');
          e.target.checked = false;
        } else if (this.form.vcn) {
          this.$message.showError('notOpenUsePointAndUseVcn');
          e.target.checked = false;
        } else if (this.form.useTicketFlag) {
          this.$message.showError('notUseTicketAndPointFlag');
          e.target.checked = false;
        } else {
          this.$message.reset();
          this.form.usePointFlag = 1;
        }
      } else {
        if (this.isEdit && this.parentEventDetail?.usePointFlag) {
          this.$message.showError('notChangeOffUsePointFlag');
          this.form.usePointFlag = 1;
          e.target.checked = true;
        } else {
          this.$message.reset();
          this.form.usePointFlag = 0;
        }
      }
    },
    handleUseCpm(e) {
      if (e.target.checked) {
        if (this.form.useTicketFlag) {
          this.$message.showError('notUseTicketAndCpmFlag');
          e.target.checked = false;
        } else {
          this.$message.reset();
          this.form.useCpm = true;
        }
      } else {
        this.$message.reset();
        this.form.useCpm = false;
      }
    },
    handleUseTicketFlag(e) {
      if (e.target.checked) {
        if (this.form.useCpm && this.form.useTotalBalanceFlag) {
          this.$message.showError('notUseTicketIfOnCpmAndBalance');
          e.target.checked = false;
        } else if (this.form.useCpm && this.form.usePointFlag) {
          this.$message.showError('notUseTicketPointAndCpmFlag');
          e.target.checked = false;
        } else if (this.form.useTotalBalanceFlag) {
          this.$message.showError('notUseTicketAndBalanceFlag');
          e.target.checked = false;
        } else if (this.form.useCpm) {
          this.$message.showError('notUseTicketAndCpmFlag');
          e.target.checked = false;
        } else if (this.form.usePointFlag) {
          this.$message.showError('notUseTicketAndPointFlag');
          e.target.checked = false;
        } else if (this.isEdit && !this.parentEventDetail?.hasTicketType && !!this.parentEventDetail?.numberChildEvent) {
          this.$message.showError('notUseTicketFlagMoreThanNumberChildEvent');
          e.target.checked = false;
        } else {
          this.$message.reset();
          this.form.useTicketFlag = 1;
        }
      } else {
        if (this.isEdit && this.parentEventDetail?.hasTicketType) {
          this.$message.showError('notOffTicketFlagIfHasTicketType');
          this.form.useTicketFlag = 1;
          e.target.checked = true;
        } else {
          this.$message.reset();
          this.form.useTicketFlag = 0;
        }
      }
    },
    handleDisplayShopMenuFront(e) {
      if (e.target.checked) {
        if (this.form.shopMenus.gallery && this.form.shopMenus.menu && this.form.shopMenus.coupon) {
          this.$message.showError('notOnDisplayShopMenuFront');
          e.target.checked = false;
        } else {
          this.$message.reset();
          this.form.displayShopMenuFront = 1;
        }
      } else {
        this.$message.reset();
        this.form.displayShopMenuFront = 0;
      }
    },
    handleUsePaymentPasscode() {
      this.form.descriptionRegister = null;
      this.form.descriptionMyPage = null;
    },
    async getDetail() {
      if (this.isEdit) {
        await this.$store.dispatch('event/getParentEventDetail', this.subdomain);
      }
    },
    convertToArray(arr) {
      return arr || []
    },
    addTag: function() {
      this.$message.reset();
      if (this.tagName === '') {
        this.errors.tagName = true;
        this.$message.noSelectedCategory();
      } else if (this.categoriesList.length >= 20) {
        this.$message.showError('maxCategory', 'max', 20);
      } else if (this.categoriesList.includes(this.tagName)) {
        this.errors.tagName = true;
        this.$message.showError('duplicateCategory');
      } else if (this.tagName.length > 12) {
        this.errors.tagName = true;
        this.$message.showError('categoryNameMaxLength', 'max', 12);
      } else {
        this.errors.tagName = false;
        this.form.addCategories.push(this.tagName);
        this.tagName = '';
      }
    },
    deleteTag: function(tagName) {
      const addCategoryIndex = this.form.addCategories.findIndex(item => item === tagName);
      const categoryIndex = this.form.categories.findIndex(item => item.name === tagName);
      if (categoryIndex !== -1) {
        this.form.deleteCategories.push(this.form.categories[categoryIndex]?.id);
        this.form.categories.splice(categoryIndex, 1);
      } else {
        this.form.addCategories.splice(addCategoryIndex, 1);
      }
    },
    formatForm() {
      return {
        ...this.form,
        clients: this.form.clients.map(item => item.id),
        cpmChargeRate: !this.form?.useCpm ? null : this.form?.cpmChargeRate
      }
    },
    handleSubmit: async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'parentEvent');
        return false
      } else {
        let result;
        const formData = new FormData();
        const data = convertBooleanValues(this.formatForm());
        if ((this.isEdit && this.file) || this.soundName === 'ファイルを選択') delete data.soundId;
        formData.append('file', this.file);
        buildFormData(formData, data);
        if (this.isEdit) {
          result = await this.$store.dispatch('event/updateParentEvent', formData);
          if (result) {
            await this.$router.push({ name: 'SettingEvent' });
            this.$message.updated('parentEvent');
          }
        } else {
          result = await this.$store.dispatch('event/createParentEvent', formData);
          if (result) {
            await this.$router.push({ name: 'SettingEvent' });
            this.$message.created('parentEvent');
          }
        }
      }
    },
    handleChangeSetting(e, name) {
      this.$message.showError(`notEditShopSetting${name}`);
      e.target.checked = !e.target.checked;
    },
    async uploadSound(sound) {
      this.isUploadLoading = true;
      this.soundName = sound.name;
      this.file = sound;
      this.audioSrcObj = URL.createObjectURL(sound);
      this.isUploadLoading = false;
    },
    deleteSound() {
      this.file = '';
      this.soundName = 'ファイルを選択';
      this.audioSrcObj = ''
      this.alarm.pause();
    },
    handleOnMusic() {
      this.defaultVolumeAudio.volume = 1;
      this.defaultVolumeAudio.play();
    },
    handleOnVolume() {
      if (this.alarm.src) this.alarm.pause();
      if (this.isEdit) {
        if (this.audioSrcObj === null) {
          this.alarm = new Audio(this.parentEventDetail?.sound?.url || this.infor.defaultSound);
        } else {
          this.alarm = new Audio(this.audioSrcObj || this.infor.defaultSound);
        }
      } else {
        this.alarm = new Audio(this.audioSrcObj || this.infor.defaultSound);
      }
      this.alarm.volume = this.form.volume / 100;
      this.alarm.play();
    },
    async handleUseOmise(e) {
      if (e.target.checked) {
        if (this.form.useLinkageNecFlag) {
          this.$message.showError('notOffLinkageNecOmise');
          e.target.checked = false;
          this.form.useOmise = false;
          return;
        }
      }
      if (this.form.useOmise && (!this.form.omiseId || !this.form.omisePassword || this.errors?.omiseId || this.errors?.omisePassword)) {
        const res = await this.$store.dispatch('event/createOmiseEvent');
        if (res) {
          this.form.omiseId = res?.omiseId;
          this.form.omisePassword = res?.omisePassword;
        }
      }
    },
    async handleUseLinkageNecFlag(e) {
      if (e.target.checked) {
        if (this.form.vcn && this.form.useOmise) {
          this.$message.showError('notOffLinkageNecOmiseAndVcn');
          e.target.checked = false;
          this.form.useLinkageNecFlag = false;
          return;
        } else if (this.form.vcn) {
          this.$message.showError('notOffLinkageNecVcn');
          e.target.checked = false;
          this.form.useLinkageNecFlag = false;
          return;
        } else if (this.form.useOmise) {
          this.$message.showError('notOffLinkageNecOmise');
          e.target.checked = false;
          this.form.useLinkageNecFlag = false;
          return;
        } else {
          this.$message.reset();
          this.form.useLinkageNecFlag = true;
        }
      }
      if (this.form.useLinkageNecFlag && (!this.form.necApiAuthKey || this.errors?.necApiAuthKey)) {
        const res = await this.$store.dispatch('event/createLinkageKeyEvent');
        if (res) {
          this.form.necApiAuthKey = res?.necApiAuthKey;
        }
      }
    },
    handleIdentityVerification(e) {
      if (!this.isEdit) return;
      if (!e.target.checked) {
        if (!this.parentEventDetail?.canTurnOFFIdentityVerification) {
          this.$message.showError('notOffIdentity');
          e.target.checked = true;
          this.form.identityVerification = true;
        } else {
          this.$message.reset();
          this.form.identityVerification = false;
        }
      } else {
        this.$message.reset();
        this.form.identityVerification = true;
      }
    },
    handleUseSms(e) {
      if (!this.isEdit) return;
      if (!e.target.checked) {
        if (!this.parentEventDetail?.canTurnOFFUseSms) {
          this.$message.showError('notOffSms');
          e.target.checked = true;
          this.form.useSms = true;
        } else {
          this.$message.reset();
          this.form.useSms = false;
        }
      } else {
        this.$message.reset();
        this.form.useSms = true;
      }
    },
    resetIdentityForm() {
      this.form.hostIdentity = null;
      this.form.realm = null;
      this.form.clientIdIdentity = null;
      this.form.passClientIdentity = null;
      this.form.typeEndPointDigitalIdentity = null;
      this.form.clientIdDigitalIdentity = null;
      this.errors.hostIdentity = false;
      this.errors.realm = false;
      this.errors.clientIdIdentity = false;
      this.errors.passClientIdentity = false;
      this.errors.typeEndPointDigitalIdentity = false;
      this.errors.clientIdDigitalIdentity = false;
    },
  },
  created() {
    const loading = this.$loading.show();
    this.initForm();
    Promise.all([
      this.$store.dispatch('client/getClientList', {
        limit: 9999,
        type: "parent"
      }),
      this.getDetail(),
    ]).then(() => {
      if (this.isEdit) {
        this.form = { ...this.form, ...this.parentEventDetail };
        if(!this.parentEventDetail.shopSettings) this.form.shopSettings = this.defaultShopSetting;
        if(!this.parentEventDetail.shopMenus) this.form.shopMenus = this.defaultShopMenus;
        this.soundName = this.parentEventDetail?.sound?.fileName;
        this.form.soundId = this.parentEventDetail?.sound?.id;
      }
      if (!this.clientList.length) {
        this.$message.showError('noClientData');
      }
      this.selectedIds = new Set((this.form.clients || []).map(item => item.id));
    }).finally(() => {
      this.$loading.clear(loading);
    })
  },
};
</script>

<style scoped>
.form-control-linkage {
  min-width: 570px;
}
</style>
